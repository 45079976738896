<template>
     <v-row>
          <v-col cols="12">
              <template>
                    <v-simple-table :dense="dense">
                    <template v-slot:default>
                         <thead>
                         </thead>
                         <tbody>
                         <tr
                              v-for="(item, key,i) in table"
                              :key="key+i"
                         >
                              <template v-if="isString(item) || isNumber(item) || item == null">
                                   <th>{{ $t('attributes.'+key+'.label') }}</th>
                                   <template v-if="isDate(key)">
                                        <td v-if="item">{{ moment(item).format("dddd, D MMMM YYYY") }}</td>
                                        <td v-else>null</td>
                                   </template>
                                   <template v-else-if="isDateTime(key)">
                                        <td v-if="item">{{ moment(item).format("dddd, D MMMM YYYY HH:mm") }}</td>
                                        <td v-else>null</td>
                                   </template>
                                   <template v-else>
                                       <td>{{ (item) ? item : "null" }}</td>
                                   </template>
                              </template>
                              <template v-else-if="isBoolean(item) || item == null">
                              <th>{{ $t('attributes.'+key+'.label') }}</th>
                              <td class="text-center">
                                   <v-icon color="green accent-4" v-if="item==true">mdi-check-circle</v-icon>
                                   <v-icon color="red accent-4" v-else-if="item==false">mdi-cancel</v-icon>
                                   <v-icon color="grey accent-4" v-else>mdi-help-circle</v-icon>
                              </td>
                              </template>
                              <!-- <template v-else-if="isArray(item)">
                                   <th>{{ $t('attributes.'+key+'.label') }}</th>
                                   <td> 
                                        <v-chip class="ma-2" outlined pill size="small" v-for="val in item" :key="val">{{val}}</v-chip>
                                   </td>
                              </template> -->
                              <template v-else-if="isArray(item)">
                                   <td colspan="2" class="pa-2">
                                        <v-expansion-panels inset elevation="0">
                                        <v-expansion-panel>
                                             <v-expansion-panel-header>
                                             <b>{{ $t('attributes.'+key+'.label') }}</b>
                                             </v-expansion-panel-header>
                                             <v-expansion-panel-content v-for="(obj,n) in item" :key="n">
                                                       
                                                            <simple-table :table="createObj({'tokey':obj},'tokey',key)" ></simple-table>
                                                       
                                             </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        </v-expansion-panels>
                                   </td>
                              </template>
                              <template v-else-if="isObject(item)">
                                   <td colspan="2" class="pa-2">
                                        <v-expansion-panels inset elevation="0">
                                        <v-expansion-panel>
                                             <v-expansion-panel-header>
                                             <b>{{ $t('attributes.'+key+'.label') }}</b>
                                             </v-expansion-panel-header>
                                             <v-expansion-panel-content>
                                                  <div v-if="isImage(key)" class="grey darken-4 py-3 px-3 text-center"     >
                                                       <v-img
                                                       :src="item.url.image"
                                                       :lazy-src="item.url.thumbnail"
                                                       contain
                                                       class="mx-auto"
                                                       max-height="250px"       
                                                       max-width="250px"              
                                                       ></v-img>
                                                  </div>
                                                  <simple-table :table="item" ></simple-table>
                                             </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        </v-expansion-panels>
                                   </td>
                              </template>
                         </tr>
                         </tbody>
                    </template>
                    </v-simple-table>
               </template>
          </v-col>
     </v-row>
</template>

<script>
  export default {
     name:"SimpleTable",
     props:{
          table: {
               type: [Object, Array],
               required: true,
          },
          dense:{
               type: Boolean,
               required: false,
               default: false,
          }
     },
     data:()=>({
          datesKey: ['date_of_birth','start','end'],
          datesTimeKey: ['created_at','updated_at','email_verified_at','phone_verified_at','issued_on','confirmed_at'],
          datesImages: ['thumbnail','avatar']
     }),
     methods:{
          isString(val){
               return typeof val === 'string' || val instanceof String
          },
          isObject(val){
               return (!!val) && (val.constructor === Object);
          },
          isArray(val){
               return (!!val) && (val.constructor === Array)
          },
          isBoolean(val){
               return typeof val === 'boolean' || val instanceof Boolean
          },
          isNumber(val){
               return typeof val === 'number' || val instanceof Number
          },
          isDate(key){
               return this.datesKey.includes(key)
          },
          isDateTime(key){
               return this.datesTimeKey.includes(key)
          },
          isImage(key){
               return this.datesImages.includes(key)
          },
          createObj(object, key, newKey)  {

               const clonedObj = Object.assign({}, object);
               const targetKey = clonedObj[key];

               delete clonedObj[key];

               clonedObj[newKey] = targetKey;

               return clonedObj;

          }
     }
  }
</script>